@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    /* Colors */
    --white: #fff;
    --blue: #0057b1;
    --dark-blue: #0b345e;
    --light-blue: #ecf9ff;
    --shadow: 0 3px 6px 0 #cecece;
    --shadow-pressed: 0 3px 10px 3px #cecece;
    /* Border Radius */
    --radius: 1.875rem;
}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

table,
td,
th {
    border-collapse: collapse;
}

html {
    scroll-behavior: smooth;
}


@layer utilities {
    @responsive {
        .remove-button-bg {
            -webkit-tap-highlight-color: transparent;
          }
        .h-550{
            height:550px;
        }
        .h-610{
            height:610px;
        }
        .h-650{
            height:650px;
        }
        .w-430{
            width:430px;
        }
        .w-01{
            width:1px;
        }
        .h-85{
            height:85px;
        }
        .min-w-400{
            min-width:400px;
        }
        .inputWidth15{
            width: 1.5rem!important;
        }
        .inputWidth13{
            width: 1.3rem!important;
        }
        .inputWidth10{
            width: 0.8rem!important;
        }
    }
  }